/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import PageTemplate from "../templates/page"

const IndexPage = ({ data, ...restProps }) => {
  return <PageTemplate data={data} {...restProps} />
}

export default IndexPage

export const query = graphql`
  query {
    contentfulPage(slug: { eq: "index" }) {
      ...PageData
      ...Blade
    }
  }
`
