/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import Page from "../components/page"
import Blade from "../components/blade"
import Ad from "../components/ad"

const PageTemplate = ({ data, ...restProps }) => {
  return (
    <Page data={data.contentfulPage}>
      {data.contentfulPage.blades?.map((blade, index) => (
        <Blade
          key={index}
          blade={blade}
          desktopAdUrl={data.contentfulPage.desktopAdUrl}
          mobileAdUrl={data.contentfulPage.mobileAdUrl}
          {...restProps}
        />
      ))}
    </Page>
  )
}

export default PageTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      ...PageData
      ...Blade
    }
  }
`
